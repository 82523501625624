import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createBooksCashDeskPayment, getBooksCashDeskPayments, getOneBooksCashDeskPayment, updateBooksCashDeskPayment, } from './actions';
var initialState = {
    booksCashDeskPayments: { docs: [], meta: {} },
    booksCashDeskPayment: null,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'bookCashDeskPayments',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // CREATE ONE
        builder
            .addCase(createBooksCashDeskPayment.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createBooksCashDeskPayment.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.booksCashDeskPayment = action.payload.data;
        })
            .addCase(createBooksCashDeskPayment.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // UPDATE ONE
        builder
            .addCase(updateBooksCashDeskPayment.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateBooksCashDeskPayment.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.booksCashDeskPayment = action.payload.data;
        })
            .addCase(updateBooksCashDeskPayment.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getOneBooksCashDeskPayment.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneBooksCashDeskPayment.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.booksCashDeskPayment = action.payload.data;
        })
            .addCase(getOneBooksCashDeskPayment.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ALL
        builder
            .addCase(getBooksCashDeskPayments.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getBooksCashDeskPayments.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.booksCashDeskPayments = action.payload;
        })
            .addCase(getBooksCashDeskPayments.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // // DELETE ONE
        // builder
        //   .addCase(deleteOneCashPayment.pending, (state) => {
        //     state.status = IStatus.LOADING;
        //   })
        //   .addCase(deleteOneCashPayment.fulfilled, (state, action) => {
        //     state.status = IStatus.SUCCEEDED;
        //     state.cashRegisterPayments.docs = state.cashRegisterPayments.docs.filter(
        //       (expense) => expense._id !== action.meta.arg.cashPaymentId
        //     );
        //   })
        //   .addCase(deleteOneCashPayment.rejected, (state) => {
        //     state.status = IStatus.FAILED;
        //   });
    },
});
export default slice.reducer;
